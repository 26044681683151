<template>
  <div class="accomplish">
    <div class="mainbody">
      <div class="mainbody-title"><p>{{type === 'success' ? '个人卖家-完成注册' : '个人卖家-申请'}}</p></div>
     <div class="schedule">
        <a-steps direction="vertical" size="small" :current="type == 'success' ? 2 : 1">
          <a-step title="1.个人信息" description="" />
          <a-step title="2.注册个人卖家" description="" />
          <a-step title="3.完成注册" description="" />
        </a-steps>
      </div>
      <div class="success-reg">
        <div class="success-text">
          <p class="icon-check"><a-icon :type="type === 'success' ? 'check-circle' : 'info-circle'" /></p>
          <p class="fs24">{{type === 'success' ? '恭喜完成注册' : '申请成为个人卖家'}}</p>
          <p><img src="" alt=""></p>
          <!-- <p>您也可以通过手机扫码进行签署</p> -->
        </div>
        <div class="success-btn">
          <a-button class="go-index" @click="type === 'success' ? $router.push('/') : $router.back()">
              {{type === 'success' ? '进入首页' : '返回'}}
          </a-button>
          <a-button class="go-shop" :loading="loading" @click="enter">
            <!-- <router-link :to="'/shop/s'+$route.query.id"> -->
            <span style="font-size: 20px"><a-icon type="user" /></span>{{type === 'success' ? '店铺主页' : '确定'}}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { personal } from "@/api/seller";
export default {
  data() {
    return {
      steps:3,
      type: null,
      loading: false
    };
  },
  watch: {
    '$route'() {
      this.type = this.$route.query.type
    }
  },
  mounted() {
    console.log(222);
    this.type = this.$route.query.type
  },
  methods: {
    enter() {
        console.log(555);
      if (this.type === 'success') {
        window.location.href = `${window.location.origin}/seller-content?entityId=${this.$route.query.id}`
      } else {
        const { individualId, regionId } = this.$route.query
        this.loading = true 
        personal({individualId,regionId}).then(res => {
          this.loading = false 
          console.log(res);
          if (res.code == 200) {
            this.$store.dispatch("getuserMenu")
            this.$router.replace('/accomplishnom?type=success&id='+ res.data)
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.accomplish {
  margin-bottom: 100px;
   max-width: 1440px;
   min-width: 990px; 
    margin: 20px auto;
   @media screen and (max-width: 1440px){
   padding: 0 15px;
   }

  .mainbody {
    height: 572px;
    background-color: #ffffff;
    position: relative;
    .mainbody-title {
      padding: 30px 0 0 50px;
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 20px;
      font-weight: 700;
    }
    .success-reg {
      padding: 100px 0 0 0;
      text-align: center;
      .success-text {
        .fs24 {
          font-size: 24px;
          padding: 30px 0 0 0;
          color: #666666;
        }
        .fs16{
          padding: 20px 0 0 0;
          color: #999999;
          font-size: 16px;
        }
      }
    }
    .success-btn {
      display: flex;
      margin: 0 auto;
      padding-top: 55px;
      justify-content: center;
      .go-index,
      .go-shop {
        margin-left: 20px;
        width: 200px;
        font-size: 16px;
        height: 45px;
        line-height: 45px;
      }
      .go-index {
        color: #ffffff;
        background-color: rgba(26, 188, 156, 1);
        :hover {
          background-color: #0fce96;
          opacity: 0.8;
        }
      }
      .go-shop {
        color: #0fce96;
        outline: 1px solid #0fce96;
        :hover {
          background-color: #0fce96;
          color: #ffffff;
        }
      }
    }
  }
}
.icon-check{
    font-size: 100px;
    color: #0fce96;
}
.schedule {
  max-width: 150px;
  position: absolute;
  top: 120px;
  right: 20px;              
  .ant-steps-vertical {
    /deep/.ant-steps-item-content {
      height: 100px;
    }
  }
}
</style>